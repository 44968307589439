import Swiper, { Navigation, Keyboard, Lazy, Pagination } from 'swiper';
Swiper.use([Navigation, Keyboard, Lazy, Pagination])
export const SwiperGallery = () => {
  const sliders = [...document.querySelectorAll('.swiper')]
  const pagination = [...document.querySelectorAll('.swiper-pagination')]
  const next = [...document.querySelectorAll('.swiper-button-next')]
  const prev = [...document.querySelectorAll('.swiper-button-prev')]

  for (let [key, slider] of Object.entries(sliders)) {
    new Swiper(slider, {
      slidesPerView: 1,
      // Disable preloading of all images
      preloadImages: false,
      watchSlidesVisibility: true,
      // Enable lazy loading
      lazy: {
        loadOnTransitionStart: true,
        loadPrevNext: true,
        loadPrevNextAmount: 3,
        checkInView: true,
      },
      loopAdditionalSlides: 6,
      spaceBetween: 0,
      loop: true,
      autoHeight: true, //enable auto height
      slideToClickedSlide: true,
      pagination: {
         el: pagination[key],
         type: 'custom',
        renderCustom: function (swiper, current, total) {
            return current.toString().padStart(total.toString().length,0) + ' / ' + (total);
        }
       },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },

      navigation: {
        nextEl: next[key],
        prevEl: prev[key],
      },

      // breakpoints: slider.dataset.swiperBreakpoints ? JSON.parse(slider.dataset.swiperBreakpoints) : {0: {slidesPerView: 1,spaceBetween: 10}, 767: {slidesPerView: 1 ,spaceBetween: 46}, 1200: {slidesPerView: 4,spaceBetween: 6  }}

      breakpoints: {
        0: {slidesPerView: 1,spaceBetween: 10},
        767: {slidesPerView: 1 ,spaceBetween: 46},
        1200: {
          slidesPerView: slider.dataset.swiperSlidesPerViewLarge ? slider.dataset.swiperSlidesPerViewLarge : 1,
          spaceBetween: 6
        }
      }


    });
  }

}
